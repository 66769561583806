import "./billing-payouts-nav.css";
import { NavLink } from "react-router-dom";
import RoleRequired from "../../utils/role-required/RoleRequired";
import { toast } from "react-toastify";
import { getBillingsCSV } from "../../../actions/billings";

export default function BillingPayoutsNav() {
	return (
		<div className="sb-filter-box">
			<div className="sb-filter-cont">
				<div className="sb-filter">
					<div className="bpn-cont">
						<div className="bpn-title-cont">
							{/* <NavLink
								to="/payouts/fulfillment"
								className="bpn-tab"
								activeClassName="bpn-tab-active"
							>
								Fulfillment
							</NavLink> */}
							<NavLink
								to="/payouts/treasury"
								className="bpn-tab"
								activeClassName="bpn-tab-active"
							>
								Treasury
							</NavLink>
							<RoleRequired>
								<NavLink
									to="/payouts/batches"
									className="bpn-tab"
									activeClassName="bpn-tab-active"
								>
									Batches
								</NavLink>
							</RoleRequired>
						</div>
					</div>
					<div
						className="export-btn pointer"
						onClick={() => {
							getBillingsCSV().catch(console.error);
							toast.success("You will receive an email with the file");
						}}
					>
						Export
					</div>
				</div>
			</div>
		</div>
	);
}
