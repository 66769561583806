import { useMemo } from "react";
import { useQuery } from "react-query";
import { getSanitizedMarketplaces } from "../actions/marketplace";
import { queryClient } from "../components/App";
import { IMarketplace } from "../interfaces";
import queryString from "query-string";

function useSanitizedMarketplaces(marketplaceIntegration?: boolean) {
  const key = useMemo(
    () =>
      `sanitizedMarkeplaces?${queryString.stringify({
        marketplaceIntegration,
      })}`,
    [marketplaceIntegration]
  );
  const data = useQuery(
    key,
    () =>
      getSanitizedMarketplaces(marketplaceIntegration) as Promise<
        IMarketplace[]
      >,
    { refetchOnWindowFocus: false }
  );

  return useMemo(
    () => ({
      ...data,
      invalidate: () => queryClient.invalidateQueries(key),
    }),
    [data, key]
  );
}

export default useSanitizedMarketplaces;
