import React, { useContext } from 'react';
import { IZeoosOrder, Role } from '../../../../../interfaces';
import { CountriesContext } from '../../../../context';
import { ICountryM } from '../../../country-management/CountryManagement';
import SingleOrderRefundModal from './singleorderview/SingleOrderRefundModal';
import useUser from '../../../../../hooks/useUser';
import { refundOrder } from '../../../../../actions/order';
import { toast } from 'react-toastify';

interface Props {
    order: IZeoosOrder;
}
  
const SingleOrderDetail: React.FC<Props> = ({order}) => {
  const countries = useContext(CountriesContext) as ICountryM[];
  const { user } = useUser();
  const [refundModal, setRefundModal] = React.useState(false);
  const refundButton = !["canceled","closed","refused","returned","refunded"].includes(order.status); 

  const onModalClose = (e: React.MouseEvent) => {
    e.stopPropagation();
    setRefundModal(false);
  };

  const onModalSubmit = async (
    order_id: number,
    reason : string,
  ) => {
    const res = await refundOrder({
			id: order_id,
			reason,
		});

		if (res.success) {
			toast.success(res.message);
		} else {
			toast.error(res.message, { autoClose: 5000 });
		}

    setRefundModal(false);
  };

    
  return (
      <>
          <div className='single-order-title-cont'>
              <h2 className='single-order-title2'>Order Details</h2>
              <div className='in-row align-center'>
                {refundButton && user.role === Role.admin &&
                  <div className="so-button refund" onClick={() => setRefundModal(true)}>
                    Refund Order
                  </div>
                }
              </div>
          </div>
          <div className='order-border'></div>
          <div className='so-details-cont'>
              <div className='so-details-box single-order-text'>
                  <b>ID ERP</b>
                  <span>{order.order_erp}</span>
              </div>
              <div className='so-details-box single-order-text'>
                  <b>ID Platform</b>
                  <span>{order.order_marketplace}</span>
              </div>
              <div className='so-details-box single-order-text'>
                  <b>Platform Name</b>
                  <div className='in-row align-cener'>
                      {countries.find((x: any) => order.customer?.country === x.name) ? (
                          <img                            
                          src={
                              countries.find((x: any) => order.customer?.country === x.name)
                              ?.iconUrl
                          }
                          width="24px"
                          alt=""
                          />
                      ) : (
                          <span>{order.customer?.country}</span>
                      )}
                      <span className='pl2' style={{width:"max-content"}}>{order.zeoosName}</span>
                  </div>
              </div>
              <div className='so-details-box single-order-text'>
                  <b>Order Date</b>
                  <span>{order.date}</span>
              </div>
              <div className='so-details-box single-order-text'>
                  <b>Status Order</b>
                  <span>{order.status}</span>
              </div>
              
          </div>
          {refundModal && (
            <SingleOrderRefundModal
              order={order}
              onModalClose={onModalClose}
              onModalSubmit={onModalSubmit}
            />
          )}
      </>
  );
}

export default SingleOrderDetail;
