import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Tab } from "@mui/material";
import { Modal } from "components";
import React, { useCallback, useMemo, useState, useEffect } from "react";
import { ModalOffer } from "types/offer";
import { convertPrice } from "../UpdateGestao";
import useSanitizedMarketplaces from "../../../../../hooks/useSanitizedMarketplaces";
import { getPlatformOffer, useProductOffers } from "../../../../../actions/v2/offer";
import useActiveVendors from "../../../../../hooks/useActiveVendors";
import useUser from "../../../../../hooks/useUser";
import { Role } from "../../../../../interfaces";

interface Props {
  close: () => void;
  offer: ModalOffer;
  openPriceUpdateModal?: (offer: ModalOffer) => void;
}

const CheckOffersModal: React.FC<Props> = ({
  close,
  offer,
  openPriceUpdateModal,
}) => {
  const offers = useProductOffers(offer.sku, offer.platformId);
  const first = useMemo(() => offers.data?.[0], [offers.data]);

  const marketplaces = useSanitizedMarketplaces();
  const marketplace = marketplaces.data?.find(
    (m) => m._id === offer.platformId
  );

  const [value, setValue] = useState("1");
  const [platformOffer, setPlatformOffer] = useState<{ link?: string, active?: boolean, integrated?: boolean }>({})

  useEffect(() => {
    const getPlatformOfferLink = async () => {
      const platformOffer = await getPlatformOffer(offer.ean, offer.platformId);
      setPlatformOffer(platformOffer);
    };
    getPlatformOfferLink();
  }, [offer]);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const onModalClose = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      close();
    },
    [close]
  );

  const { user } = useUser();
  const isAdmin = useMemo(() => user?.role === Role.admin, [user?.role]);
  const { data: vendors } = useActiveVendors();

  const getSellerName = (id: string) => {
    const vendor = vendors?.find((v: any) => v._id === id);
    if (isAdmin || user.seller?.id === vendor?.id) {
      return vendor?.displayName || vendor?.name || "";
    } else {
      return "*****";
    }
  };

  return (
    <Modal onModalClose={onModalClose} isOpened>
      <>
        <div
          style={{ maxWidth: "640px", minWidth: "480px" }}
          className="table-modal-form-cont p4"
        >
          {platformOffer.link ? (
            <a href={platformOffer.link} target="_blank" rel="noopener noreferrer">
              <div className="inv-title-modal1-cont">
                <span className="inv-title-modal1-text">
                  {marketplace?.zeoosName}
                </span>
              </div>
            </a>
          ) : (
            <div className="inv-title-modal1-cont not-allowed">
              <span className="inv-title-modal1-text">
                {marketplace?.zeoosName}
              </span>
            </div>
          )}
          <div className="mb4 in-row align-start">
            <div
              style={{ width: "122px", height: "120px" }}
              className="int-set-match-logo"
            >
              <img
                width="120px"
                height="120px"
                className="pb1"
                src={offer?.image}
                alt=""
              />
            </div>
            <div className="ml2 mt4 int-text in-column text-aligns align-start justify-start">
              <span style={{ lineHeight: "20px" }}>{offer?.name}</span>
              <div className="in-row mt2 align-center justify-start width100">
                <span className="int-text mr4">
                  <b className="mr1">SKU:</b>
                  {offer?.sku}
                </span>
                <span className="int-text">
                  <b className="mr1">EAN:</b>
                  {offer?.ean}
                </span>
              </div>
              <div className="in-row mt2 align-center justify-start width100">
                <span className="int-text mr4">
                  <b style={{ fontSize: "24px" }} className="mr1">
                    {`${convertPrice(first?.price?.pvpFinal || 0)} €`}
                  </b>

                  {first?.seller && (
                    <span className="opacity5">
                      by {getSellerName(first?.seller._id)}
                    </span>
                  )}
                </span>
              </div>
              {/* {!product?.marketplaces[marketplace?.zeoosName as any]
										?.integrated && (
											<div className="in-row mt2 align-center justify-start width100">
												<span
													className="int-text mr4"
													style={{ color: "#E80000", fontWeight: "bold" }}
												>
													Product not integrated yet
												</span>
											</div>
										)} */}
            </div>
          </div>
          <TabContext value={value}>
            <TabList
              className="inv-mod-tabs"
              style={{ borderBottom: "1px solid #E1E1E1" }}
              onChange={handleChange}
              aria-label="lab API tabs example"
            >
              {/* <Tab className="ges-tab-sync ges-tab-sync-active" label="Platform Buybox" value="2" /> */}
              <Tab
                className="ges-tab-sync ges-tab-sync-active"
                label="Zeoos Ranking"
                value="1"
              />
            </TabList>
            {/* <TabPanel className="m0 p0" value="2">
                            <table
                                style={{ fontSize: "14px" }}
                                className="mapping-modal-table"
                            >
                                <thead>
                                    <tr className="mapping-modal-results-title justify-between m0 pt2 pb2">
                                        <th className="inv-modal1-table-seller">
                                            Buybox on {marketplace?.zeoosName}
                                        </th>
                                        <th className="inv-modal1-table-column">Price</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {product?.marketplaces?.[
                                        marketplace?.zeoosName as any
                                    ]?.buybox?.sellers?.map((m: any, index: number) => {
                                        return (
                                            <tr
                                                key={m._id}
                                                className="mapping-modal-result-box justify-between pt1 pb1"
                                            >
                                                <td className="inv-modal1-table-seller">
                                                    <b className="mr2">{index + 1}</b> {m.name}
                                                </td>
                                                <td className="inv-modal1-table-column justify-start bold">
                                                    <span>{`${convertPrice(m.price || 0)} €`}</span>
                                                    {index === 0 &&
                                                        product?.marketplaces?.[
                                                            marketplace?.zeoosName as any
                                                        ]?.buybox?.ranking === 1 ? (
                                                        <img
                                                            className="ml2"
                                                            src="/icons/inv-modal1-rank.svg"
                                                            alt=""
                                                            title="Buybox"
                                                        />
                                                    ) : (
                                                        ""
                                                    )}
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </TabPanel> */}
            <TabPanel className="m0 p0" value="1">
              <table
                style={{ fontSize: "14px" }}
                className="mapping-modal-table"
              >
                <thead>
                  <tr className="mapping-modal-results-title m0 pt2 pb2">
                    <th className="inv-modal1-table-seller">Zeoos Ranking</th>
                    <th className="inv-modal1-table-column">Selling</th>
                    <th className="inv-modal1-table-column">Stock</th>
                    <th className="inv-modal1-table-column price">Price</th>
                  </tr>
                </thead>
                <tbody>
                  {offers.data?.map((x) => {
                    const sellerName = getSellerName(x.seller._id);
                    const clickable = sellerName !== "*****";
                    // const clickable = false;

                    return (
                      <tr
                        key={x.sku}
                        onClick={() => {
                          if (clickable) {
                            openPriceUpdateModal?.({
                              sku: offer.sku,
                              platformId: offer.platformId,
                              seller: x.seller._id,
                              price: x.price,
                              ean: offer.ean,
                              name: offer.name,
                              image: offer.image,
                            });
                            // setModal(true);
                            // setSelectedSeller(seller.seller);
                            // setProduct(item as any);
                            // setMarketplace(marketplace);
                            // setSelectedWinner(winner);
                          }
                        }}
                        className={`mapping-modal-result-box pt1 pb1 ${
                          clickable ? "pointer platform-list" : ""
                        }`}
                      >
                        <td className="inv-modal1-table-seller">
                          <b className="mr2">{x.ranking}º</b>
                          {sellerName}
                        </td>
                        <td className="inv-modal1-table-column bold">
                          {platformOffer.integrated && platformOffer.active && x.active && x.stock ? (
                            <>
                              <img
                                className="mr1"
                                src="/icons/inv-modal1-green-dott.svg"
                                alt=""
                              />
                              <span
                                style={{
                                  color: "#299C00",
                                  textTransform: "uppercase",
                                }}
                              >
                                on
                              </span>
                            </>
                          ) : (
                            <>
                              <img
                                className="mr1"
                                src="/icons/inv-modal1-red-dott.svg"
                                alt=""
                              />
                              <span
                                style={{
                                  color: "#E80000",
                                  textTransform: "uppercase",
                                }}
                              >
                                off
                              </span>
                            </>
                          )}
                        </td>
                        <td className="inv-modal1-table-column">
                          {sellerName === "*****" ? "*" : x.stock}
                        </td>
                        <td className="inv-modal1-table-column justify-start price bold">
                          <span>{`${convertPrice(
                            x.price?.pvpFinal || 0
                          )} €`}</span>
                          {x.ranking === 1 ? (
                            <img
                              className="ml1"
                              src="/icons/ranking-modal-yellow.svg"
                              alt=""
                              title="1º on Zeoos"
                            />
                          ) : (
                            ""
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </TabPanel>
          </TabContext>
          <div className="in-row width100 align-center justify-center">
            <div onClick={onModalClose} className="grid-modal-close-btn">
              Close
            </div>
          </div>
        </div>
      </>
    </Modal>
  );
};

export default CheckOffersModal;
