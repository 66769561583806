import React from "react";
import { NavLink } from "react-router-dom";
import { Role } from "../../../interfaces";
import RoleRequired from "../../utils/role-required/RoleRequired";

export default function SettingsNav() {
	return (
		<>
			<div className="table-value-search-cont mt2">
				<div className="table-title-nav-cont width100">
					<RoleRequired roles={[Role.admin, Role.sellerAdmin]}>
						<NavLink
							to="/users-management"
							className="table-tab-sync"
							activeClassName="table-tab-sync-active"
						>
							User Management
						</NavLink>
					</RoleRequired>

					<NavLink
						to="/notifications-settings"
						className="table-tab-sync"
						activeClassName="table-tab-sync-active"
					>
						Notification Settings
					</NavLink>

					<RoleRequired role={Role.user} strict>
						<NavLink
							to="/seller-information"
							className="table-tab-sync"
							activeClassName="table-tab-sync-active"
						>
							Seller Information
						</NavLink>
					</RoleRequired>

					<RoleRequired role={Role.admin}>
						<NavLink
							to="/country-management"
							className="table-tab-sync"
							activeClassName="table-tab-sync-active"
						>
							Country Management
						</NavLink>
						<NavLink
							to="/password-management"
							className="table-tab-sync"
							activeClassName="table-tab-sync-active"
						>
							Password Management
						</NavLink>
						<NavLink
							to="/reviews"
							className="table-tab-sync"
							activeClassName="table-tab-sync-active"
						>
							Reviews
						</NavLink>
						<NavLink
							to="/pim-translation"
							className="table-tab-sync"
							activeClassName="table-tab-sync-active"
						>
							PIM Translation
						</NavLink>
						<NavLink
							to="/history-log"
							className="table-tab-sync"
							activeClassName="table-tab-sync-active"
						>
							History Log
						</NavLink>
					</RoleRequired>
				</div>
			</div>
			<div className="mapping-title-border"></div>
		</>
	);
}
