import React, { useState, forwardRef, useMemo, useEffect } from "react";
import "./acompanhamento.css";
import "../tables.css";
import { Selects } from "./Selects";
import { TableAcompanhamento } from "./TableAcomp";
import Preloader from "../../../ui/preloader/Preloader";
import moment from "moment";
import DatePicker from "react-datepicker";
import { useListOrders } from "../../../../hooks/useOrders";
import { getPaginatedInventoryProducts } from "../../../../actions/marketplace";
import {
	IPaginatedRequest, IPaginatedResponse, IProductGestao, IZeoosOrder,
} from "../../../../interfaces";
// import { GestaoFilter } from "./GestaoFilter";
import { useQuery } from "react-query";
import { useDebouncedValue } from "@mantine/hooks";
import useActiveVendors from "../../../../hooks/useActiveVendors";
import queryString from "query-string";
import { useAtom } from "jotai";
import { STOCK_CONDITIONS } from "types";
import useSanitizedMarketplaces from "../../../../hooks/useSanitizedMarketplaces";
import { useClickOutside } from "@mantine/hooks";
import { selectedProductsAtom } from "../gestao/Gestao";
import { listOrders } from "../../../../actions/order";
import { LinearProgress } from "@mui/material";
// import OptimazeOffers from "./OptimazeOffers";

export type OrderResponse = {
	orders: IZeoosOrder[];
	total: number;
	perPage: number;
	page: number;
	lastPage: boolean;
};

export type AdditionalOrderFilter = Omit<IPaginatedRequest, "perPage" | "platform" | "vendorName"> & { vendorId: IPaginatedRequest["vendorName"]; from?: number | Date; to?: number | Date; platform: IPaginatedRequest["platformName"]; show: IPaginatedRequest["perPage"] };

export default function Acompanhamento() {
	const [currentConfig, setCurrentConfig] = useState<AdditionalOrderFilter>({
		show: 50,
		page: 1,
		search: "",
		country: "All countries",
		vendorId: "All vendors",
		stockCondition: STOCK_CONDITIONS[0],
		platform: "All marketplaces",
		status: "All statuses"
	});
	const [debouncedConfig] = useDebouncedValue(currentConfig, 500);
	const [filteredMarketplaces, setFilteredMarketplaces] = useState([]);

	const { isLoading: loadMarketplaces, data: marketplaces } =
		useSanitizedMarketplaces();

	const vendors = useActiveVendors() as any;

	const key = useMemo(() => {
		return `listOrders?${queryString.stringify(debouncedConfig)}`;
	}, [debouncedConfig]);

	const orders = useQuery(
		key,
		() =>
			listOrders(
				debouncedConfig,
			) as never as OrderResponse,
		{ refetchOnWindowFocus: false }
	);

	// const [showExportForm, setShowExportForm] = useState(false);

	if (loadMarketplaces || vendors.isLoading) {
		return (
			<div className="center-loader">
				<Preloader />
			</div>
		);
	}

	return (
		<>
			<div className="main-body">
				{orders?.isRefetching && (
					<div style={{
						position: 'fixed',
						top: 0,
						width: '100vw',
						zIndex: 1000000,
					}}>
						<LinearProgress />
					</div>
				)}
				<div className="main-container">
					<div className="table-title-cont">
						<div className="main-table-title-cont">
							<div className="acompan-select-cont justify-between" style={{ width: "100%" }}>
								<div className="in-row align-center">
									<span className="pim-language-text pl4" style={{ textTransform: 'capitalize' }}>
										All my orders
									</span>
								</div>

								<div className="in-row align-center">
									<Selects
										marketplaces={marketplaces as any}
										vendors={vendors.data!}
										currentConfig={currentConfig}
										setCurrentConfig={setCurrentConfig}
										setFilteredMarketplaces={setFilteredMarketplaces}
										filterFrom={"inventory"}
									/>
								</div>
							</div>
						</div>
					</div>
					<TableAcompanhamento
						orders={(orders?.data || []) as any}
						setCurrentConfig={setCurrentConfig}
						currentConfig={currentConfig}
						loadProducts={orders.isLoading}
					/>
				</div>
			</div>
		</>
	);
}
