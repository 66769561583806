import React, { useCallback, useState } from "react";
import { IMarketplace, IPaginatedRequest } from "../../../interfaces";
import Paginator from "../../ui/pagination";
import { getLogoSvg } from "./MarketplacesList";
import CountryFlag from "../../ui/country-flag/CountryFlag";
import { CreateEditMarketplaceForm } from "./CreateEditMplc";
import RoleRequired from "../../utils/role-required/RoleRequired";
import { Role } from "../../../interfaces";
import ConditionalLink from "../../utils/conditional-link";
import CircularPreloader from "../../ui/circular-preloader/CircularPreloader";
import useUser from "../../../hooks/useUser";
import { PlatformAvailability } from "../vendors-management/seller-platforms/PlatformAvailability";
import TooltipIcon from "../logistic/TooltipIcon";
import { useHistory } from "react-router-dom";

interface Props {
  marketplaces: any;
  setCurrentConfig: React.Dispatch<React.SetStateAction<IPaginatedRequest>>;
  currentConfig: IPaginatedRequest;
  loadMarketplaces: boolean;
}

export const PlatformTable: React.FC<Props> = ({
  marketplaces,
  setCurrentConfig,
  currentConfig,
  loadMarketplaces,
}) => {
  const { user } = useUser();

  const [marketplace, setMarketplace] = useState<IMarketplace>();
  const [editModal, setEditModal] = useState<boolean>(false);
  const history = useHistory()

  const onChangePage = useCallback(
    async (config: IPaginatedRequest) => {
      setCurrentConfig((c: IPaginatedRequest) => {
        return {
          ...c,
          ...config,
        };
      });
    },
    [setCurrentConfig]
  );

  const marketplacesData = () => {
    return user.role !== Role.admin
      ? marketplaces?.data.filter((x: IMarketplace) => x.active)
      : marketplaces?.data;
  };

  return (
    <>
      <div className="table-main-title-cont">
        <div className="mplc-main-title-search-value-cont">
          <div className="search-box">
            <div className="search_form">
              <div className="table-search-box">
                <form
                  className="table-search-form"
                  onSubmit={(e) => e.preventDefault()}
                >
                  <input
                    className="table-search-input"
                    placeholder="Search marketplace"
                    onChange={(e) =>
                      setCurrentConfig((conf: IPaginatedRequest) => ({
                        ...conf,
                        search: e.target.value,
                      }))
                    }
                    value={currentConfig.search}
                  />
                  <img
                    className="table-search-image"
                    src="/icons/search.svg"
                    width="25px;"
                    alt=""
                  />
                </form>
              </div>
            </div>
            <div className="table-value">
              <span className="table-main-title-text">
                {loadMarketplaces ? (
                  <CircularPreloader />
                ) : (
                  <span className="table-main-title-text">
                    {user.role !== Role.admin
                      ? marketplacesData().length
                      : marketplaces?.total}{" "}
                    marketplaces
                  </span>
                )}
              </span>
            </div>
          </div>
          <div className="marketplace-pagination">
            <Paginator
              data={!loadMarketplaces ? marketplaces : {}}
              refetch={onChangePage as any}
            />
          </div>
        </div>
      </div>
      <div style={{ height: "max-content" }} className="table-overflow">
        {loadMarketplaces ? (
          <></>
        ) : (
          <table
            style={{
              position: "sticky",
              zIndex: 101,
              top: "2px",
              width: "100%",
            }}
          >
            <thead>
              <tr
                style={{ marginTop: "-5px", width: "100%" }}
                className="table-results-title"
              >
                <RoleRequired role={Role.admin}>
                  <th className="marketplace-sell-min">Platform</th>
                  <th className="marketplace-sell-min">Name</th>
                  <th className="marketplace-sell-min">Country</th>
                  <th className="marketplace-sell-min">Platform type</th>
                  {/* <th className="marketplace-sell-min">Integrated products</th>
									<th className="marketplace-sell-min">Products selling</th> */}
                  <th className="marketplace-sell-min">Edit account</th>
                  <th className="marketplace-sell-min">Platform status</th>
                </RoleRequired>
                <RoleRequired role={Role.user} strict>
                  <th className="marketplace-actions">Platform</th>
                  <th className="marketplace-actions">Platform account</th>
                  <th className="marketplace-pais">Country</th>
                  <th className="marketplace-actions">Platform type</th>
                  {/* <th className="marketplace-actions">My products</th> */}
                  <th className="marketplace-actions">Status</th>
                </RoleRequired>
              </tr>
            </thead>
          </table>
        )}
        <table className="table-results">
          <tbody>
            {marketplacesData()?.map((item: IMarketplace, index: number) => (
              <React.Fragment key={index}>
                <RoleRequired role={Role.admin}>
                  <tr style={{position:'relative'}}
                    className={`table-result-box minheight100 ${!item.active && "bg-noneactive"
                      }`}
                  >
                    <div
                    onClick={() => {
                      if(user.role === Role.admin){
                        history.push("/platform/" + item.zeoosName)
                      }
                    }}
                      className="width100 align-center pointer"
                    >
                      <td className="marketplace-sell-min">
                        <img
                          className="img-border p1"
                          src={
                            item.marketplaceImg ||
                            `https://vinuus-portal.s3.eu-west-3.amazonaws.com/production/assets/${getLogoSvg(
                              item
                            )}.svg`
                          }
                          alt=""
                          width="90px"
                          height="90px"
                        />
                      </td>
                      <td className="marketplace-sell-min">
                        <h3 className="marketplace-text">{item.zeoosName}</h3>
                      </td>
                      <td className="marketplace-sell-min">
                        <div className="marketplace-text">
                          <CountryFlag
                            country={item.country}
                            className="table-search-image"
                            width="35px"
                          />
                        </div>
                      </td>
                      <td className="marketplace-sell-min">
                        <span className="marketplace-text">{item.type}</span>
                      </td>
                      {/* <td className="marketplace-sell-min">
											<span className="marketplace-text"></span>
										</td>
										<td className="marketplace-sell-min">
											<span className="marketplace-text"></span>
										</td> */}
                      <td  className="marketplace-sell-min">
                        <div onClick={(event) => {
                          event.stopPropagation();
                          history.push(`/platforms/create-edit?action=edit&&platformName=${item.zeoosName}`);
                        }} className="marketplace-table-edit-button in-row decoration-none ">
                          <TooltipIcon
                            title={""}
                            icon={"/icons/edit_platform.svg"}
                            iconHover={"/icons/edit_platform_hover.svg"}
                            size={{ w: "30px", h: "30px" }}
                          />
                          <span className="ml2">edit account</span>
                        </div>
                      </td>
                      <td className="marketplace-sell-min">
                        <div
                          className={`mwidth100 pr1 pl1 ${item.active ? "border-active" : "border-deactive"
                            }`}
                        >
                          <span
                            className={`marketplace-text ${item.active
                                ? "text-activated"
                                : "text-deactivated"
                              }`}
                          >
                            {item.active ? "Activated" : "Deactivated"}
                          </span>
                        </div>
                      </td>
                    </div>
                  </tr>
                </RoleRequired>
                <RoleRequired role={Role.user} strict>
                  <tr
                    className={`table-result-box minheight100 ${!user.seller?.activeMarketplaces.includes(
                      item.zeoosName
                    ) && "bg-noneactive"
                      }`}
                    key={index}
                  >
                    <td className="marketplace-actions">
                      <img
                        className="img-border p1"
                        src={
                          item.marketplaceImg ||
                          `https://vinuus-portal.s3.eu-west-3.amazonaws.com/production/assets/${getLogoSvg(
                            item
                          )}.svg`
                        }
                        alt=""
                        width="90px"
                        height="90px"
                      />
                    </td>
                    <td className="marketplace-actions">
                      <h3 className="marketplace-text">{item.zeoosName}</h3>
                    </td>
                    <td className="marketplace-pais">
                      <div className="marketplace-text">
                        <CountryFlag
                          country={item.country}
                          className="table-search-image"
                          width="35px"
                        />
                      </div>
                    </td>
                    <td className="marketplace-actions">
                      <span className="marketplace-text">{item.type}</span>
                    </td>
                    {/* <td className="marketplace-actions">
											<span className="marketplace-text">1000</span>
										</td> */}
                    <PlatformAvailability
                      vendorId={user.seller?.id}
                      isActive={user.seller?.activeMarketplaces.includes(
                        item.zeoosName
                      )}
                      label1={"Activate"}
                      label2={"Deactivate"}
                      module={"platforms"}
                      platform={item.zeoosName}
                    />
                  </tr>
                </RoleRequired>
              </React.Fragment>
            ))}
          </tbody>
        </table>
        {editModal && (
          <CreateEditMarketplaceForm
            marketplace={marketplace}
            setModal={setEditModal}
            modal={editModal}
            action={"edit"}
          />
        )}
      </div>
    </>
  );
};
