import React, { useState, useEffect, memo } from "react";
import { Switch, BrowserRouter, Route, useHistory } from "react-router-dom";
import Login from "./routes/login/Login";
import Esqueci from "./routes/login/Esqueci";
import Reset from "./routes/login/Reset";
import {
  createPermission,
  PermissionScope,
  PermissionType,
} from "../actions/user";
import { CountriesContext } from "./context";
import SingleMarketplace from "./routes/single-marketplace/SingleMarketplace";
import CategoryRate from "./routes/single-marketplace/CategoryRate";
import AccessDenied from "./routes/accessdenied/AccessDenied";
import WrapBI from "./routes/business-intelligence/BusinessIntelligence";
import UsersManagement from "./routes/users-management/UsersManagement";
import Reviews from "./routes/reviews/Reviews";
import Tax from "./routes/mappings/tax/Tax";
import Freight from "./routes/mappings/freight/Freight";
import PlatformRates from "./routes/mappings/platform-rates/PlatformRates";
import Billing from "./routes/billing/Billing";
import Translator from "./routes/translator/Translator";
import Profile from "./routes/profile/Profile";
import Gestao from "./routes/tables/gestao/Gestao";
import Comparador from "./routes/tables/comparador/Comparador";
import Acompanhamento from "./routes/tables/acompanhamento/Acompanhamento";
import PermissionRoute from "./utils/permission-route/PermissionRoute";
import CssBaseline from "@mui/material/CssBaseline";
import ToastMessage from "./ui/toast-message/ToastMessage";
import PanelBar from "./ui/panel-bar/PanelBar";
import "./app.css";
import Pim from "./routes/pim/Pim";
import { Requests } from "./ui/header/requests/Requests";
import { MarketplacesList } from "./routes/marketplaces/MarketplacesList";
import Preloader from "./ui/preloader/Preloader";
import VendorsManagement from "./routes/vendors-management/VendorsManagement";
import PimCreateScheme from "./routes/pim/scheme/CreateScheme";
import PimScheme from "./routes/pim/scheme/EditScheme";
import {
  CountryManagement,
  ICountryM,
} from "./routes/country-management/CountryManagement";
import { getCountries } from "../actions/countryManagement";
import { FileManagement } from "./routes/file-management/FileManagement";
import Vendors from "./routes/vendors/Vendors";
import JobsPanel from "./routes/sync/jobs-panel/JobsPanel";
import Queue from "./routes/sync/queue/Queue";
import SingleOrder from "./routes/tables/acompanhamento/singleorder/SingleOrder";
import BatchList from "./routes/sync/batch-list/BatchList";
import SingleBatch from "./routes/sync/batch-list/single-batch/SingleBatch";
import PimMain from "./routes/pim/PimMain";
import { QueryClient } from "react-query";
// import DigitalAssets from "./routes/digital-assets/DigitalAssets";
import Intelligence from "./routes/intelligence/Intelligence";
import PasswordManagement from "./routes/password-management/PasswordManagement";
import MFALogin from "./routes/login/MFALogin";
import PIMTranslation from "./routes/pim-translation/PIMTranslation";
import { RequestsNoties } from "./routes/requests-notifications/RequestsNoties";
import HistoryLog from "./routes/history-log/HistoryLog";
import SingleOrderView from "./routes/tables/acompanhamento/singleorder/singleorderview/SingleOrderView";
import SingleOrderPurchase from "./routes/tables/acompanhamento/singleorder/singleorderpurchase/SingleOrderPurchase";
import ProductAttrubutes from "./routes/single-marketplace/ProductAttributes";
import StatusOrder from "./routes/single-marketplace/StatusOrder";
import MatchFields from "./routes/single-marketplace/MatchFields";
import BatchProducts from "./routes/tables/gestao/BatchProducts";
import Rules from "./routes/single-marketplace/Rules";
import Pricing from "./routes/pricing/Pricing";
import PricingEvent from "./routes/pricing/planned-events-and-standing-edit/PricingEvent";
import SellerProduct from "./routes/vendors-management/seller-products/SellerProduct";
import Logistic from "./routes/logistic/Logistic";
// import CreatePayment from "./routes/billing/payment-batch/CreatePayment";
import EditPayment from "./routes/billing/payment-batch/EditPayment";
import SellersOnboarding from "./routes/vendors-management/onboarding/Onboarding";
import IntelligenceProduct from "./routes/intelligence/product/IntelligenceProduct";
import IntelligenceOverview from "./routes/intelligence/overview/IntelligenceOverview";
import IntelligenceSettings from "./routes/intelligence/settings/IntelligenceSettings";
import IntelligenceSettingsMatch from "./routes/intelligence/settings/IntelligenceSettingsMatch";
import IntelligenceSettingsSingle from "./routes/intelligence/settings/IntelligenceSettingsSingle";
// import Payouts from "./routes/payouts/Payouts";
import PayoutsPartners from "./routes/payouts/PayoutsPartners";
import DemoRequest from "./routes/vendors-management/demo-request/DemoRequest";
import { atomWithStorage } from "jotai/utils";
import { useAtom } from "jotai";
import { Role } from "../interfaces";
import UserPreview from "./routes/user-preview";
import Notifications from "./routes/notifications/Notifications";
import SingleNotification from "./routes/notifications/single-notification/SingleNotification";
import NotificationsSettings from "./routes/notifications/notifications-settings/NotificationsSettings";
import SingleTicket from "./routes/tickets/single-ticket/SingleTicket";
import Tickets from "./routes/tickets/Tickets";
import useUser from "../hooks/useUser";
import NotificationAlerts from "./ui/notification-alerts";
import SellerInfo from "./routes/seller-info/SellerInfo";
import Onboarding from "./routes/onboarfing-steps/Onboarding";
import Contrato from "./routes/onboarfing-steps/contrato/Contrato";
import SetupAccount from "./routes/login/SetupAccount";
import ProductImportReports from "./routes/catalog/product-import-reports";
import ProductBatchLog from "./routes/catalog/product-batch-log/ProductBatchLog";
import OfferBatchLogs from "./routes/tables/gestao/offer-batch-logs/OfferBatchLogs";
import TreasuryTable from "./routes/billing/treasury/TreasuryTable";
import PaymentBatchTable from "./routes/billing/payment-batch/PaymentBatchTable";
import UploadFilesInBatch from "./routes/catalog/upload-files-in-batch/UploadFilesInBatch";
import  CreateEditPlatform  from "./routes/marketplaces/CreateEditPlatform";

export const queryClient = new QueryClient();

const navOpenAtom = atomWithStorage("navOpenAtom", true);

export default memo(function App() {
  const [loading, setLoading] = useState(true);

  const [countries, setCountries] = useState<ICountryM[]>([]);
  const [open, setOpen] = useAtom(navOpenAtom);
  const history = useHistory();
  const { user, isLoading } = useUser();

  useEffect(() => {
    async function initializeState() {
      try {
        setCountries(await getCountries());
      } catch (error) {
        console.error(error);
      }
      setLoading(false);
    }

    initializeState();
  }, [history, user]);

  return (
    <>
      <ToastMessage />
      {loading || isLoading ? (
        <div className="center-loader">
          <Preloader />
        </div>
      ) : (
        <BrowserRouter>
          <CountriesContext.Provider value={countries}>
            {user ? (
              <div className="root">
                {user.isOnboardingFinished &&
                  (user?.seller ? user?.seller?.onBoardingPriceStock : true) ? (
                  <>
                    <CssBaseline />
                    <NotificationAlerts />

                    <PanelBar setOpen={setOpen} open={open} />
                    <main className={open ? "content-shift" : "content"}>
                      <div className="drawer-header" />
                      <Switch>
                        <Route path="/login" exact>
                          <Login />
                        </Route>

                        {/* Platform Routes */}
                        <PermissionRoute
                          path="/platforms"
                          permission={createPermission(
                            PermissionScope.platforms,
                            PermissionType.read
                          )}
                          // roles={[Role.sellerAdmin]}
                          exact
                        >
                          <MarketplacesList />
                        </PermissionRoute>
                        <PermissionRoute
                          path="/platforms/create-edit"
                          permission={createPermission(
                            PermissionScope.platforms,
                            PermissionType.read
                          )}
                          // roles={[Role.sellerAdmin]}
                          exact
                        >
                          <CreateEditPlatform/>
                        </PermissionRoute>
                        <PermissionRoute
                          path="/platform/:zeoosName"
                          permission={createPermission(
                            PermissionScope.platforms,
                            PermissionType.read
                          )}
                          exact
                        >
                          <SingleMarketplace />
                        </PermissionRoute>

                        <PermissionRoute
                          path="/platform/:zeoosName/category-rate"
                          permission={createPermission(
                            PermissionScope.platforms,
                            PermissionType.read
                          )}
                          exact
                        >
                          <CategoryRate />
                        </PermissionRoute>

                        {/* Pim Routes */}
                        <PermissionRoute
                          path="/productInfo/:sku"
                          permission={createPermission(
                            PermissionScope.pim,
                            PermissionType.read
                          )}
                          exact
                        >
                          <Pim />
                        </PermissionRoute>

                        <PermissionRoute
                          path="/catalog/:tab"
                          exact
                          permission={createPermission(
                            PermissionScope.pim,
                            PermissionType.read
                          )}
                          roles={[Role.user, Role.sellerAdmin]}
                        >
                          <PimMain />
                        </PermissionRoute>

                        <PermissionRoute
                          path="/my/catalog/reports"
                          exact
                          permission={createPermission(
                            PermissionScope.pim,
                            PermissionType.read
                          )}
                          roles={[Role.sellerAdmin]}
                        >
                          <ProductImportReports />
                        </PermissionRoute>

                        <PermissionRoute
                          path="/my/catalog/:tab"
                          exact
                          permission={createPermission(
                            PermissionScope.pim,
                            PermissionType.read
                          )}
                          roles={[Role.user, Role.sellerAdmin]}
                        >
                          <PimMain />
                        </PermissionRoute>
                        <PermissionRoute
                          path="/catalogs-upload-files-in-batch"
                          permission={createPermission(
                            PermissionScope.mappings,
                            PermissionType.read
                          )}
                          exact
                        >
                          <UploadFilesInBatch />
                        </PermissionRoute>

                        <PermissionRoute
                          path="/product-batch-log/:batchId"
                          exact
                          permission={createPermission(
                            PermissionScope.pim,
                            PermissionType.read
                          )}
                          roles={[Role.sellerAdmin]}
                        >
                          <ProductBatchLog />
                        </PermissionRoute>
                        {/* <PermissionRoute
                        path="/pim-management"
                        permission={createPermission(
                          PermissionScope.pim,
                          PermissionType.read
                        )}
                        exact
                      >
                        <PimManagement />
                      </PermissionRoute> */}
                        <PermissionRoute
                          path="/pim/scheme"
                          permission={createPermission(
                            PermissionScope.pim,
                            PermissionType.read
                          )}
                          exact
                        >
                          <PimCreateScheme />
                        </PermissionRoute>
                        <PermissionRoute
                          path="/pim/scheme/:name"
                          permission={createPermission(
                            PermissionScope.pim,
                            PermissionType.read
                          )}
                          exact
                        >
                          <PimScheme />
                        </PermissionRoute>

                        {/* Digital Assets Routes */}
                        {/* <PermissionRoute
                        path="/digital-assets"
                        permission={createPermission(
                          PermissionScope.digital_assets,
                          PermissionType.read
                        )}
                        exact
                      >
                        <DigitalAssets />
                      </PermissionRoute> */}

                        {/* Password Management */}
                        <PermissionRoute path="/password-management" exact>
                          <PasswordManagement />
                        </PermissionRoute>

                        {/* BI Routes */}
                        <PermissionRoute
                          path={["/", "/dashboard"]}
                          permission={createPermission(
                            PermissionScope.dashboard,
                            PermissionType.read
                          )}
                          roles={[Role.sellerAdmin]}
                          exact
                        >
                          <WrapBI />
                        </PermissionRoute>
                        {/* Notifications */}
                        <Route path={["/notifications"]} exact>
                          <Notifications />
                        </Route>
                        {/* Seller Info */}
                        <Route path={["/seller-information"]} exact>
                          <SellerInfo />
                        </Route>
                        <Route path={["/single-notification"]} exact>
                          <SingleNotification />
                        </Route>
                        <Route path={["/contract"]} exact>
                          <Contrato />
                        </Route>
                        <Route path={["/notifications-settings"]} exact>
                          <NotificationsSettings />
                        </Route>
                        {/* Tickets */}
                        <Route path={["/tickets"]} exact>
                          <Tickets />
                        </Route>
                        <Route path={"/ticket/:id"} exact>
                          <SingleTicket />
                        </Route>
                        {/* OnboardingSteps */}
                        {/* <PermissionRoute
													path="/onboarding-steps"
													permission={createPermission(
														PermissionScope.onboarding,
														PermissionType.read
													)}
													roles={[Role.sellerAdmin]}
													exact
												>
													<OnboardingSteps />
												</PermissionRoute> */}
                        {/* Settings Routes */}
                        <PermissionRoute
                          path="/users-management"
                          permission={createPermission(
                            PermissionScope.settings,
                            PermissionType.read
                          )}
                          roles={[Role.sellerAdmin]}
                          exact
                        >
                          <UsersManagement />
                        </PermissionRoute>
                        <PermissionRoute
                          path="/history-log"
                          permission={createPermission(
                            PermissionScope.settings,
                            PermissionType.read
                          )}
                          roles={[Role.sellerAdmin]}
                          exact
                        >
                          <HistoryLog />
                        </PermissionRoute>
                        <PermissionRoute
                          path="/reviews"
                          permission={createPermission(
                            PermissionScope.settings,
                            PermissionType.read
                          )}
                          exact
                        >
                          <Reviews />
                        </PermissionRoute>
                        <PermissionRoute
                          path="/pim-translation"
                          permission={createPermission(
                            PermissionScope.settings,
                            PermissionType.read
                          )}
                          exact
                        >
                          <PIMTranslation />
                        </PermissionRoute>
                        <PermissionRoute
                          path="/translator"
                          permission={createPermission(
                            PermissionScope.settings,
                            PermissionType.read
                          )}
                          exact
                        >
                          <Translator />
                        </PermissionRoute>
                        <PermissionRoute
                          path="/requests"
                          permission={createPermission(
                            PermissionScope.settings,
                            PermissionType.read
                          )}
                          exact
                        >
                          <Requests />
                        </PermissionRoute>
                        <PermissionRoute
                          path="/requests-noties"
                          permission={createPermission(
                            PermissionScope.settings,
                            PermissionType.read
                          )}
                          exact
                        >
                          <RequestsNoties />
                        </PermissionRoute>
                        <PermissionRoute
                          path="/country-management"
                          permission={createPermission(
                            PermissionScope.settings,
                            PermissionType.read
                          )}
                          exact
                        >
                          <CountryManagement />
                        </PermissionRoute>
                        <PermissionRoute
                          path="/file-management"
                          permission={createPermission(
                            PermissionScope.settings,
                            PermissionType.read
                          )}
                          exact
                        >
                          <FileManagement />
                        </PermissionRoute>

                        <PermissionRoute path="/profile" exact>
                          <Profile />
                        </PermissionRoute>

                        {/* Inventory Routes */}
                        <PermissionRoute
                          path="/offers/:tab"
                          permission={createPermission(
                            PermissionScope.inventory,
                            PermissionType.read
                          )}
                          roles={[Role.sellerAdmin]}
                          exact
                        >
                          <Gestao />
                        </PermissionRoute>
                        <PermissionRoute
                          path="/offer-import"
                          permission={createPermission(
                            PermissionScope.inventory,
                            PermissionType.read
                          )}
                          roles={[Role.sellerAdmin]}
                          exact
                        >
                          <OfferBatchLogs />
                        </PermissionRoute>

                        <PermissionRoute path="/comparador" exact>
                          <Comparador />
                        </PermissionRoute>

                        {/* Order Routes */}
                        <PermissionRoute
                          path="/orders"
                          permission={createPermission(
                            PermissionScope.orders,
                            PermissionType.read
                          )}
                          roles={[Role.sellerAdmin]}
                          exact
                        >
                          <Acompanhamento />
                        </PermissionRoute>
                        {/* SingleOrder Routes */}
                        <PermissionRoute
                          path="/orders/:id"
                          permission={createPermission(
                            PermissionScope.orders,
                            PermissionType.read
                          )}
                          roles={[Role.sellerAdmin]}
                          exact
                        >
                          <SingleOrder />
                        </PermissionRoute>
                        {/* SingleOrderView Routes */}
                        <PermissionRoute
                          path="/orders/:order/:shipping"
                          permission={createPermission(
                            PermissionScope.orders,
                            PermissionType.read
                          )}
                          roles={[Role.sellerAdmin]}
                          exact
                        >
                          <SingleOrderView />
                        </PermissionRoute>
                        {/* SingleOrderView Routes */}
                        <PermissionRoute
                          path="/orders/:order/:shipping/purchase"
                          permission={createPermission(
                            PermissionScope.orders,
                            PermissionType.read
                          )}
                          roles={[Role.sellerAdmin]}
                          exact
                        >
                          <SingleOrderPurchase />
                        </PermissionRoute>
                        <PermissionRoute
                          path="/pricing"
                          permission={createPermission(
                            PermissionScope.mappings,
                            PermissionType.read
                          )}
                          exact
                        >
                          <Pricing />
                        </PermissionRoute>
                        <PermissionRoute
                          path="/pricing-event/:id"
                          permission={createPermission(
                            PermissionScope.mappings,
                            PermissionType.read
                          )}
                          exact
                        >
                          <PricingEvent />
                        </PermissionRoute>

                        {/* Mappings Routes */}
                        <PermissionRoute
                          path="/tax"
                          permission={createPermission(
                            PermissionScope.mappings,
                            PermissionType.read
                          )}
                          exact
                        >
                          <Tax />
                        </PermissionRoute>
                        <PermissionRoute
                          path="/freight"
                          permission={createPermission(
                            PermissionScope.mappings,
                            PermissionType.read
                          )}
                          exact
                        >
                          <Freight />
                        </PermissionRoute>
                        <PermissionRoute
                          path="/platform-rates"
                          permission={createPermission(
                            PermissionScope.mappings,
                            PermissionType.read
                          )}
                          exact
                        >
                          <PlatformRates />
                        </PermissionRoute>
                        <PermissionRoute
                          path="/vendor"
                          permission={createPermission(
                            PermissionScope.mappings,
                            PermissionType.read
                          )}
                          exact
                        >
                          <Vendors />
                        </PermissionRoute>
                        <PermissionRoute
                          path="/product-attributes/:zeoosName"
                          permission={createPermission(
                            PermissionScope.mappings,
                            PermissionType.read
                          )}
                          exact
                        >
                          <ProductAttrubutes />
                        </PermissionRoute>
                        <PermissionRoute
                          path="/status-order/:zeoosName"
                          permission={createPermission(
                            PermissionScope.mappings,
                            PermissionType.read
                          )}
                          exact
                        >
                          <StatusOrder />
                        </PermissionRoute>
                        <PermissionRoute
                          path="/match-fields/:categoryId/:zeoosName"
                          permission={createPermission(
                            PermissionScope.mappings,
                            PermissionType.read
                          )}
                          exact
                        >
                          <MatchFields />
                        </PermissionRoute>
                        <PermissionRoute
                          path="/rules/:zeoosName"
                          permission={createPermission(
                            PermissionScope.mappings,
                            PermissionType.read
                          )}
                          exact
                        >
                          <Rules />
                        </PermissionRoute>
                        <PermissionRoute
                          path="/batch-products"
                          permission={createPermission(
                            PermissionScope.mappings,
                            PermissionType.read
                          )}
                          exact
                        >
                          <BatchProducts />
                        </PermissionRoute>
                        {/* Syncs Routes */}
                        <PermissionRoute
                          path="/jobs-panel"
                          permission={createPermission(
                            PermissionScope.syncs,
                            PermissionType.read
                          )}
                          exact
                        >
                          <JobsPanel />
                        </PermissionRoute>
                        <PermissionRoute
                          path="/queue"
                          permission={createPermission(
                            PermissionScope.syncs,
                            PermissionType.read
                          )}
                          exact
                        >
                          <Queue />
                        </PermissionRoute>

                        {/* Syncs Routes */}
                        <PermissionRoute
                          path="/jobs-panel"
                          permission={createPermission(
                            PermissionScope.syncs,
                            PermissionType.read
                          )}
                          exact
                        >
                          <JobsPanel />
                        </PermissionRoute>
                        <PermissionRoute
                          path="/queue"
                          permission={createPermission(
                            PermissionScope.syncs,
                            PermissionType.read
                          )}
                          exact
                        >
                          <Queue />
                        </PermissionRoute>
                        <PermissionRoute
                          path="/batch-list"
                          permission={createPermission(
                            PermissionScope.syncs,
                            PermissionType.read
                          )}
                          exact
                        >
                          <BatchList />
                        </PermissionRoute>
                        <PermissionRoute
                          path="/batch/:id"
                          permission={createPermission(
                            PermissionScope.syncs,
                            PermissionType.read
                          )}
                          exact
                        >
                          <SingleBatch />
                        </PermissionRoute>

                        {/* Logistic Routes */}
                        <PermissionRoute
                          path="/logistic"
                          permission={createPermission(
                            PermissionScope.logistic,
                            PermissionType.read
                          )}
                          roles={[Role.sellerAdmin]}
                          exact
                        >
                          <Logistic />
                        </PermissionRoute>

                        {/* Billing Routes */}
                        <PermissionRoute
                          path="/billing"
                          permission={createPermission(
                            PermissionScope.billings,
                            PermissionType.read
                          )}
                          roles={[Role.sellerAdmin]}
                          exact
                        >
                          <Billing />
                        </PermissionRoute>
                        <PermissionRoute
                          path="/payouts/fulfillment"
                          permission={createPermission(
                            PermissionScope.billings,
                            PermissionType.read
                          )}
                          roles={[Role.sellerAdmin]}
                          exact
                        >
                          <Billing />
                        </PermissionRoute>
                        <PermissionRoute
                          path="/payouts/treasury"
                          permission={createPermission(
                            PermissionScope.billings,
                            PermissionType.read
                          )}
                          roles={[Role.sellerAdmin]}
                          exact
                        >
                          <TreasuryTable />
                        </PermissionRoute>
                        <PermissionRoute
                          path="/payouts/batches"
                          permission={createPermission(
                            PermissionScope.billings,
                            PermissionType.read
                          )}
                          roles={[Role.sellerAdmin]}
                          exact
                        >
                          <PaymentBatchTable />
                        </PermissionRoute>
                        <PermissionRoute
                          path="/payouts-partners"
                          permission={createPermission(
                            PermissionScope.billings,
                            PermissionType.read
                          )}
                          roles={[Role.sellerAdmin]}
                          exact
                        >
                          <PayoutsPartners />
                        </PermissionRoute>
                        <PermissionRoute
                          path="/create-paymentbatch"
                          permission={createPermission(
                            PermissionScope.billings,
                            PermissionType.read
                          )}
                          roles={[Role.sellerAdmin]}
                          exact
                        >
                          {/* <CreatePayment /> */}
                        </PermissionRoute>
                        <PermissionRoute
                          path="/payouts/payment/:id"
                          permission={createPermission(
                            PermissionScope.billings,
                            PermissionType.read
                          )}
                          roles={[Role.sellerAdmin]}
                          exact
                        >
                          <EditPayment />
                        </PermissionRoute>

                        {/* Intelligence Routes */}
                        <PermissionRoute
                          path="/intelligence"
                          permission={createPermission(
                            PermissionScope.intelligence,
                            PermissionType.read
                          )}
                          exact
                        >
                          <Intelligence />
                        </PermissionRoute>
                        <PermissionRoute
                          path="/intelligence/products"
                          roles={[Role.admin]}
                          exact
                        >
                          <IntelligenceProduct />
                        </PermissionRoute>
                        <PermissionRoute
                          path="/intelligence/overview"
                          roles={[Role.admin]}
                          exact
                        >
                          <IntelligenceOverview />
                        </PermissionRoute>
                        <PermissionRoute
                          path="/intelligence/settings"
                          roles={[Role.admin]}
                          exact
                        >
                          <IntelligenceSettings />
                        </PermissionRoute>
                        <PermissionRoute
                          path="/intelligence/robot/:id"
                          roles={[Role.admin]}
                          exact
                        >
                          <IntelligenceSettingsSingle />
                        </PermissionRoute>
                        <PermissionRoute
                          path="/intelligence/settings-match"
                          roles={[Role.admin]}
                          exact
                        >
                          <IntelligenceSettingsMatch />
                        </PermissionRoute>

                        {/* Vendors Routes */}
                        <PermissionRoute
                          path="/sellers"
                          permission={createPermission(
                            PermissionScope.vendors,
                            PermissionType.read
                          )}
                          exact
                        >
                          <VendorsManagement />
                        </PermissionRoute>
                        <PermissionRoute
                          path="/sellers-onboarding"
                          permission={createPermission(
                            PermissionScope.vendors,
                            PermissionType.read
                          )}
                          exact
                        >
                          <SellersOnboarding />
                        </PermissionRoute>
                        <PermissionRoute
                          path="/demo-request"
                          permission={createPermission(
                            PermissionScope.vendors,
                            PermissionType.read
                          )}
                          exact
                        >
                          <DemoRequest />
                        </PermissionRoute>
                        <PermissionRoute
                          path="/sellers/:id/products"
                          permission={createPermission(
                            PermissionScope.vendors,
                            PermissionType.read
                          )}
                          exact
                        >
                          <SellerProduct />
                        </PermissionRoute>
                        <PermissionRoute
                          path="/sellers/:id/platforms"
                          permission={createPermission(
                            PermissionScope.vendors,
                            PermissionType.read
                          )}
                          exact
                        >
                          <SellerProduct />
                        </PermissionRoute>

                        <Route
                          path="/permission-denied"
                          exact
                          component={AccessDenied}
                        />

                        <Route path="/user/preview" exact>
                          <UserPreview />
                        </Route>
                      </Switch>
                    </main>
                  </>
                ) : (
                  
                  <Onboarding />
                )}
              </div>
            ) : (
              <Switch>
                <Route path="/login/esqueci" exact>
                  <Esqueci />
                </Route>
                <Route path="/login/resetpass/:token" exact>
                  <Reset />
                </Route>
                <Route path="/login/mfa" exact>
                  <MFALogin />
                </Route>
                <Route path="/setup/:token" exact>
                  <SetupAccount />
                </Route>
                <Route path="/user/preview" exact>
                  <UserPreview />
                </Route>
                <Route path="*">
                  <Login />
                </Route>
              </Switch>
            )}
          </CountriesContext.Provider>
        </BrowserRouter>
      )}
    </>
  );
});
